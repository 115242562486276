// src/pages/Admin.js
import React, { useState } from "react";
import { 
  Typography, Container, Box, CssBaseline, Button 
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminErrors from '../components/AdminErrors';
import AdminPosts from '../components/AdminPosts';
import AdminCosts from '../components/AdminCosts';
import AdminUsers from '../components/AdminUsers';
import AdminSuggestions from '../components/AdminSuggestions';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
  },
});

function Admin() {
  const [selectedCollection, setSelectedCollection] = useState("Errors");

  const renderContent = () => {
    switch (selectedCollection) {
      case "Errors":
        return <AdminErrors />;
      case "Posts":
        return <AdminPosts />;
      case "Costs":
        return <AdminCosts />;
      case "Users":
        return <AdminUsers />;
      case "Suggestions":
        return <AdminSuggestions />;
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <Box display="flex" flexDirection="row" my={4} mx={2}>
          <Box width="100px" mr={4} sx={{ flexShrink: 0 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Menu
            </Typography>
            <Box display="flex" flexDirection="column">
              <Button 
                variant="contained" 
                color={selectedCollection === 'Errors' ? 'secondary' : 'primary'} 
                onClick={() => setSelectedCollection('Errors')} 
                style={{ marginBottom: '10px' }}
              >
                Errors
              </Button>
              <Button 
                variant="contained" 
                color={selectedCollection === 'Posts' ? 'secondary' : 'primary'} 
                onClick={() => setSelectedCollection('Posts')} 
                style={{ marginBottom: '10px' }}
              >
                Posts
              </Button>
              <Button 
                variant="contained" 
                color={selectedCollection === 'Costs' ? 'secondary' : 'primary'} 
                onClick={() => setSelectedCollection('Costs')}
                style={{ marginBottom: '10px' }}
              >
                Costs
              </Button>
              <Button 
                variant="contained" 
                color={selectedCollection === 'Users' ? 'secondary' : 'primary'} 
                onClick={() => setSelectedCollection('Users')}
                style={{ marginBottom: '10px' }}
              >
                Users
              </Button>
              <Button 
                variant="contained" 
                color={selectedCollection === 'Suggestions' ? 'secondary' : 'primary'} // 新增這行
                onClick={() => setSelectedCollection('Suggestions')}
              >
                Sugges
              </Button>
            </Box>
          </Box>
          <Box flex={1}>
            <Box>
              {renderContent()}
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Admin;